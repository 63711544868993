body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  color: #111111 !important;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff !important; /* Light mode background color */
    color: #111111 !important; /* Light mode text color */
  }
}

:root {
  color-scheme: only light;
  --native-dark-bg-color: #fff !important;
  --native-dark-font-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner {
  display: flex;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  width: 44px;
  height: 44px;
  animation: spinner-loading 0.5s linear infinite;
}

@keyframes spinner-loading {
  100% {
    transform: rotate(1turn);
  }
}

.content-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh
}
